// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line
// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

$('.menu-icon').on('click', function () {
  $('body').addClass('menu-open');
});

function closeMenuButton() {
  $('.close-menu').on('click', function () {
    $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
    $('body').removeClass('menu-open');
  });
}

closeMenuButton();
/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  //homepage clients slider
  $('.clients-slider')
    .slick({
      dots: false,
      infinite: true,
      arrows: true,
      speed: 1500,
      autoplaySpeed: 1500,
      autoplay: true,
      slidesToShow: 10,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 8,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    })
    .on('beforeChange', function () {
      $('.clients-slider__item').matchHeight();
    });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});
$('.menu-icon').on('click', function () {
  $('body').addClass('menu-open');
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
jQuery(document).ready(function ($) {
  function loadPosts(paged, postType, categoryID = 0) {
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        action: 'filter_posts',
        paged: paged,
        post_type: postType,
        category_id: categoryID,
        blog_page: ajax_object.blog_page,
        nonce: ajax_object.nonce,
      },
      success: function (response) {
        var data = JSON.parse(response);
        if (data.html) {
          $('.posts-list').html(data.html);
          renderPagination(data.paged, data.max_pages);
        } else {
          $('.posts-list').html('<p>No posts found.</p>');
          $('#pagination').empty();
        }
      },
      error: function () {
        $('.posts-list').html('<p>Error loading posts.</p>');
      },
    });
  }

  function renderPagination(currentPage, maxPages) {
    var paginationHTML = '';
    for (var i = 1; i <= maxPages; i++) {
      paginationHTML +=
        '<span class="page-number ' +
        (i === currentPage ? 'active' : '') +
        '" data-page="' +
        i +
        '">' +
        i +
        '</span>';
    }
    $('#pagination').html(paginationHTML);
  }

  let blogPage = $('.post-blog');
  if (blogPage.length) {
    let postType = blogPage.data('post-type');
    loadPosts(1, postType);
  }

  $('.category-filter[data-category-id="0"]').addClass('active');

  $('.category-buttons').on('click', '.category-filter', function () {
    var categoryID = $(this).data('category-id');
    let postType = $('.post-blog').data('post-type');
    $('.category-filter').removeClass('active');
    $(this).addClass('active');

    loadPosts(1, postType, categoryID);
  });

  $('#pagination').on('click', '.page-number', function () {
    var page = $(this).data('page');
    let postType = $('.post-blog').data('post-type');
    var categoryID = $('.category-filter.active').data('category-id') || 0;
    loadPosts(page, postType, categoryID);
  });
});

window.addEventListener('scroll', function () {
  var header = document.querySelector('.header');
  var sticky = header.offsetTop;

  if (window.pageYOffset > sticky) {
    header.classList.add('sticky-header');
  } else {
    header.classList.remove('sticky-header');
  }
});
